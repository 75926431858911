import StarVegaIcon from "../componenets/icons/StarVegaIcon";
import { useNavigate } from "react-router-dom";

interface handleStudentResetInterface {
  handleStudentReset?: () => void
}

function ThankYouPage({handleStudentReset} : handleStudentResetInterface): JSX.Element {
  const navigation = useNavigate();

  function handleConfirmClick(): void {
    if(handleStudentReset) {
      handleStudentReset();
    } else {
      navigation('/');
    }
  }
  return (
    <div className="thank-you">
      <div className="wrap">
        <div className="thank-you__logo">
          <StarVegaIcon />
        </div>
        <h3 className="thank-you__text thank-you__text--msg">Hvala ti na učestvovanju.</h3>
        <div className="thank-you__img-holder">
          <img src="/assets/images/vega-spark.png" alt="Vega IT Logo" />
        </div>
        <h2 className="thank-you__text thank-you__text--title">Poseti naš štand i učestvuj u dodatnim aktivacijama.</h2>
        <button className="btn" onClick={handleConfirmClick}>ZAVRŠI</button>
      </div>
    </div>
  )
}

export default ThankYouPage;