import { FormEvent, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Game } from "../models/Game";
import { Step } from "../models/Step";
import { StudentState } from "../models/StudentState";
import studentService from "../services/studentService";

interface QuizResultsProps {
  studentAndGame : {
    student: StudentState
    game: Game | Step
  }
}

const QuizResults = ({ studentAndGame } : QuizResultsProps) => {
  const [radioValue, setRadioValue] = useState<string>("1");
  const navigate = useNavigate();

  const radioValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(e.target.value)
  }

  const handleSubmit : FormEventHandler<HTMLFormElement> = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await studentService.add({...studentAndGame.student.data, results: [{game: studentAndGame.game, result: +(radioValue)}]});
    navigate('/thank-you');
  }

  return (
  <div className="quiz">
  <section className='quiz__holder'>
      <div className='registration-form__decoration-wrap'>
          <img
              className='registration-form__logo'
              src='assets/images/decoration.svg'
              alt=''
          />
      </div>
      <div className='wrap'>
        <form className="quiz__form" noValidate onSubmit={handleSubmit}>
          <label htmlFor="guess-the-bag" className="quiz__label">Izaberite broj vaših tačnih odgovora</label>
          <div className='quiz__input-holder'>
            <label className="quiz__form-item">
              1
              <input type="radio" name="result" value="1" onChange={radioValueChange} checked={radioValue === "1"} />
            </label>
            <label className="quiz__form-item">
              2
              <input type="radio" name="result" value="2" onChange={radioValueChange} checked={radioValue === "2"}  />
            </label>
            <label className="quiz__form-item">
              3
              <input type="radio" name="result" value="3" onChange={radioValueChange} checked={radioValue === "3"} />
            </label>
          </div>
          <button className="registration-form__btn"  type='submit'>
              Potvrdi
          </button>
        </form>
      </div>
    </section>
</div>)
};

export default QuizResults;