import {useState} from 'react';
import {StudentState} from '../models/StudentState';
import {Step} from '../models/Step';
import RegisterForm from '../componenets/RegisterForm';
import {Student} from '../models/Student';
import Stopwatch from '../componenets/Stopwatch';
import GuessGame from '../componenets/GuessGame';
import studentService from '../services/studentService';
import timeService from '../services/timeService';
import {useNavigate} from 'react-router-dom';
import {Game} from '../models/Game';
import QuizResults from '../componenets/QuizResults';
import { translations } from '../constants';

function IndexPage(): JSX.Element {
    const [studentState, setStudentState] = useState<StudentState>({
        data: {
            education: '',
            email: '',
            fullName: '',
            results: [],
            year: new Date().getFullYear(),
        },
        step: Step.REGISTRATION
    })

    const [currentGame, setCurrentGame] = useState<Game | Step>(Game.Rubiks_cube);

    const navigate = useNavigate();

    function handleNavigate(): void {
        navigate(`/results?game=${currentGame}`);
        return;
    }

    async function handleRegistration(student: Student, step: Step | Game) {
        setStudentState(() => ({step: step, data: student}));
        setCurrentGame(step);
    }

    async function handleStopwatchTime(result: number): Promise<void> {
        const newResults = [{game: studentState.step, result: result}];
        setStudentState((currentStudentState) => ({
            step: Step.RESULT_PAGE,
            data: {...currentStudentState.data, results: newResults}
        }));
        await studentService.add({...studentState.data, results: newResults});
    }

    async function handleGuessSubmit(result: number): Promise<void> {
        await studentService.add({...studentState.data, results: [{game: currentGame, result: result}]});
        navigate('/thank-you');
    }

    if (studentState.step === Step.GUESS_THE_NUMBER) {
        return (
          <GuessGame guessGameInputPlaceholder={translations[Game.Guess_the_number]} handleGuessSubmit={handleGuessSubmit} />
        )
    }

    if (studentState.step === Step.REGISTRATION) {
        return (
            <RegisterForm student={studentState.data} handleRegistration={handleRegistration}/>
        );
    }

    if (studentState.step === Step.RUBIKS_CUBE) {
        return (
            <Stopwatch handleStopwatchTime={handleStopwatchTime}/>
        )
    }

    if (studentState.step === Step.TANGRAM) {
        return (
          <Stopwatch handleStopwatchTime={handleStopwatchTime}/>
      )
    }

    if (studentState.step === Step.QUIZ) {
      const quizzProp = {
        student: studentState,
        game: currentGame
      }
      return <QuizResults studentAndGame={quizzProp} />
    }

    const score = studentState.data.results.find(result => result.game === currentGame) ?? {game: currentGame, result: 0};
    const timeFormatted: string = timeService.formatTime(score.result);

    return (
        <section className='results'>
            <img src='assets/images/results-decoration.svg' alt='' className='results__img'/>
            <div className='wrap'>
                <div className='results__content'>
                    <div className='results__info'>
                        <span className='results__pretitle'>Bravo!</span>
                        <span className='results__title'>Tvoj rezultat je:</span>
                        <span className='results__num'>{timeFormatted}</span>
                        <button type='button' className='btn results__btn' onClick={handleNavigate}>Rang lista</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IndexPage;
